import React from "react";
import Card from "react-bootstrap/Card";
import { ImPointRight } from "react-icons/im";

function AboutCard() {
  return (
    <Card className="quote-card-view">
      <Card.Body>
        <blockquote className="blockquote mb-0">
          <p style={{ textAlign: "justify" }}>
            Hi Everyone, I am <span className="purple">Andrew Du</span>&nbsp;
            from <span className="purple"> Beijing, China.</span>
            <br />I am currently employed as a frontend developer at{" "}
            <a
              className="purple"
              href="https://twitter.com/dappos_com"
              target="_blank"
              rel="noreferrer"
            >
              dappOS
            </a>{" "}
            in Beijing.
            <br />
            I've been working in Beijing as a software developer for over seven years.
            <br />
            <ul>
              <li className="about-activity">
                <ImPointRight /> 2016/02 -- 2019/07: Bejing Sirui Mingchuang Software Technology Co. - Full stack engineer
              </li>
              <li className="about-activity">
                <ImPointRight /> 2019/07 -- 2021/09: Hainan Weizhongjie Information Technology Co. - Manager of Technical department & Full stack engineer
              </li>
              <li className="about-activity">
                <ImPointRight /> 2021/09 -- 2022/04: Great Life Technology (Beijing) Co., Ltd. - group leader of frontend department
              </li>
              <li className="about-activity">
                <ImPointRight /> 2022/04 -- 2023/04: Zhongketaichain Software Technology Co., Ltd. - Remote full stack developer 
              </li>
              <li className="about-activity">
                <ImPointRight /> 2023/06 -- Now: Beijing Liantu Technology Co., Ltd. - Frontend developer 
              </li>
            </ul>
            <br />
            Apart from coding, some other activities that I love to do!
          </p>
          <ul>
            <li className="about-activity">
              <ImPointRight /> Playing Games, like Genshin Impact, etc.
            </li>
            <li className="about-activity">
              <ImPointRight /> Travelling
            </li>
            <li className="about-activity">
              <ImPointRight /> Escape Room
            </li>
            <li className="about-activity">
              <ImPointRight /> Cats 
            </li>
            <li className="about-activity">
              <ImPointRight /> Spoil Girlfriend 
            </li>
          </ul>

          {/* <p style={{ color: "rgb(155 126 172)" }}>
            "Strive to build things that make a difference!"{" "}
          </p>
          <footer className="blockquote-footer">Soumyajit</footer> */}
        </blockquote>
      </Card.Body>
    </Card>
  );
}

export default AboutCard;
